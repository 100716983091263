<template>
  <div class="login">
    <div class="title">
      <img src="@/assets/images/logo.png" alt="" />
      <h1>正品汇认证交易平台</h1>
      <p>Zhengpinhui authentication trading platform</p>
    </div>
    <!-- 登录 -->
    <van-form @submit="onSubmit" ref="ruleForm" v-if="login">
      <van-field
        v-model="mobile"
        type="number"
        name="mobile"
        maxlength="11"
        placeholder="请输入手机号"
        :rules="Rules.mobile"
      >
        <template #left-icon><svg-icon icon-class="phone" /></template>
      </van-field>
      <van-field
        v-model="captcha"
        type="number"
        name="captcha"
        placeholder="验证码"
        maxlength="6"
        :rules="Rules.captcha"
        ref="captcha"
        v-if="mobileLogin"
      >
        <template #left-icon><svg-icon icon-class="code" /></template>
        <template #extra>
          <button class="get_code" @click.prevent="getCode" v-if="codeShow">
            获取验证码
          </button>
          <p v-else class="count_down">{{ codeCountDown }}s后重新获取</p>
        </template>
      </van-field>
      <van-field
        v-model="password2"
        type="password"
        name="password"
        placeholder="请输入密码"
        :rules="Rules.password3"
        v-else
        ><template #left-icon><svg-icon icon-class="lock" /></template>
      </van-field>

      <van-field name="checkbox" class="protocol_box">
        <template #input>
          <van-checkbox v-model="checkbox" icon-size="12px" />
          <p class="protocol" @click="checkbox = !checkbox">
            我已阅读并接受以下条款：<a
              href="https://origmall.cn/Agreement/TermsOfService.html"
              target="_blank"
              >《服务条款》</a
            ><a
              href="https://origmall.cn/Agreement/PrivacyPolicy.html"
              target="_blank"
              >《隐私政策》</a
            ><a
              href="https://origmall.cn/Agreement/UserServiceAgreement.html"
              target="_blank"
              >《用户服务协议》</a
            ><a
              href=""
              target="_blank"
              >《正品汇个人加盟协议》</a
            >
          </p>
        </template>
      </van-field>
      <div class="submit">
        <van-button round block type="info" native-type="submit"
          >登录</van-button
        >
      </div>
      <!-- <p v-show="mobileLogin" style="margin-top: 8px; color: #666">
        未注册手机号登录后将自动注册
      </p> -->
    </van-form>
    <!-- 注册 -->
    <van-form @submit="onSubmit" ref="ruleForm" v-else>
      <van-field
        v-model="mobile"
        type="number"
        name="mobile"
        maxlength="11"
        placeholder="手机号"
        :rules="Rules.mobile"
      >
        <template #left-icon><svg-icon icon-class="phone" /></template>
      </van-field>
      <van-field
        v-model="captcha"
        type="number"
        name="captcha"
        placeholder="验证码"
        maxlength="6"
        v-if="!login"
        :rules="Rules.captcha"
        ref="captcha"
      >
        <template #left-icon><svg-icon icon-class="code" /></template>
        <template #extra>
          <button class="get_code" @click.prevent="getCode" v-if="codeShow">
            获取验证码
          </button>
          <p v-else class="count_down">{{ codeCountDown }}s后重新获取</p>
        </template>
      </van-field>
      <van-field
        v-model="password"
        type="password"
        name="password"
        placeholder="密码"
        :rules="Rules.password"
        v-if="!login && !$route.query.PDA"
        ><template #left-icon><svg-icon icon-class="lock" /></template>
      </van-field>
      <van-field
        v-model="password"
        type="password"
        name="password"
        placeholder="密码"
        :rules="Rules.password"
        v-if="$route.query.PDA && !transition"
        ><template #left-icon><svg-icon icon-class="lock" /></template>
      </van-field>
      <van-field
        v-model="password2"
        type="password"
        name="password2"
        :rules="Rules.password2"
        v-if="!transition"
        :placeholder="login ? '请输入密码' : '确认密码'"
        ><template #left-icon><svg-icon icon-class="lock" /></template
      ></van-field>
      <van-field
        v-if="$route.query.PDA"
        v-model="referrerTelNum"
        type="number"
        name="referrerTelNum"
        maxlength="11"
        placeholder="推荐人手机号，非必填"
        :disabled="referral"
      >
        <template #left-icon><svg-icon icon-class="phone" /></template>
      </van-field>
      <van-field name="checkbox" class="protocol_box">
        <template #input>
          <van-checkbox v-model="checkbox" icon-size="12px" />
          <p class="protocol" @click="checkbox = !checkbox">
            我已阅读并接受以下条款：<a
              href="https://origmall.cn/Agreement/TermsOfService.html"
              target="_blank"
              >《服务条款》</a
            ><a
              href="https://origmall.cn/Agreement/PrivacyPolicy.html"
              target="_blank"
              >《隐私政策》</a
            ><a
              href="https://origmall.cn/Agreement/UserServiceAgreement.html"
              target="_blank"
              >《用户服务协议》</a
            >
          </p>
        </template>
      </van-field>
      <div class="submit">
        <van-button round block type="info" native-type="submit">{{
          login ? "登录" : "注册"
        }}</van-button>
      </div>
    </van-form>
    <!-- <p class="switchover" v-if="!$route.query.PDA">
      <a v-show="!login" style="display: block; width: 100%"
        >已有会员账号？<span @click="switchover">请登录</span></a
      >
      <span @click="switchover" v-show="login">注册新用户</span>
      <router-link v-show="login" to="login/retrievePassword"
        >找回密码？</router-link
      >
    </p> -->
    <p class="switchover" v-if="$route.query.PDA">
      <a style="display: block; width: 100%"
        >{{ transition ? "没有账号，" : "已有会员账号？"
        }}<span @click="transition = !transition">{{
          transition ? "去注册成为店长" : "去验证手机号成为店长"
        }}</span></a
      >
      <!-- transition -->
    </p>
    <p class="mobile_login" v-show="login" @click="mobileLogin = !mobileLogin">
      {{ mobileLogin ? "账号密码登录" : "手机验证码登录"
      }}<svg-icon icon-class="jt_icon" />
    </p>
  </div>
</template>

<script>
import { setToken } from "@/utils/auth";
import { mapState } from "vuex";
import { Dialog } from "vant";

export default {
  name: "login",
  computed: {
    ...mapState("user", ["userType", "userInfo"]),
  },
  data() {
    var validatePass3 = (value, rule) => {
      rule.message = "请填写密码";
      if (value.length < 6) {
        rule.message = "密码最少为6位!";
        return false;
      }
    };
    var validatePass = (value, rule) => {
      rule.message = "请填写密码";
      if (value.length < 6) {
        rule.message = "密码最少为6位!";
        return false;
      } else if (this.password2 !== "" || value === "") {
        this.$refs.ruleForm.validateField("password2");
      }
    };
    var validatePass2 = (value, rule) => {
      rule.message = "请填写确认密码";
      if (this.password != "" && value !== this.password) {
        rule.message = "两次输入密码不一致!";
        return false;
      }
    };
    return {
      login: true,
      mobile: "", //手机号
      captcha: "", //验证码
      username: "", //用户名
      password: "", //密码
      password2: "", //确认密码
      referrerTelNum: "", //推荐人手机号
      checkbox: false, //注册协议
      codeShow: true, //验证码显示
      codeCountDown: 29, //验证码倒计时
      referral: false,
      mobileLogin: true,
      transition: false,
      Rules: {
        mobile: [
          {
            required: true,
            message: "手机号码不能为空",
            trigger: "onBlur",
          },
          {
            validator: (value) => {
              return /^(?:(?:\+|00)86)?1\d{10}$/.test(value);
            },
            message: "请输入正确格式的手机号码",
            trigger: "onBlur",
          },
        ], //手机号码校验

        captcha: [
          {
            required: true,
            message: "请填写验证码",
            trigger: "onBlur",
          },
        ],
        password: [
          { required: true, validator: validatePass, message: "请填写密码" },
        ],
        password2: [
          {
            required: true,
            validator: validatePass2,
            message: "请再次填写密码",
          },
        ],
        password3: [
          {
            required: true,
            validator: validatePass3,
            message: "请填写密码",
          },
        ],
      },
      bankInfo: "",
    };
  },
  watch: {
    $route: {
      handler: function (val, oldVal) {
        if (val.name === "register") {
          this.login = false;
        } else {
          this.login = true;
        }
        if (val.query.PDA) {
          document.getElementsByClassName("van-nav-bar")[0].style.display =
            "none";
        }
      },
      deep: true,
      immediate: true,
    },
  },
  created() {
    if (this.$route.query.referrerTelNum) {
      sessionStorage.setItem("referral", this.$route.query.referrerTelNum);
      this.$router.replace("/register?PDA=1");
    }
    if (sessionStorage.getItem("referral")) {
      this.referrerTelNum = sessionStorage.getItem("referral");
      this.referral = true;
    }
    if (sessionStorage.getItem("userUuid")) this.getBankStoreDetail();
  },
  methods: {
    //登录注册
    onSubmit(values) {
      if (!this.checkbox) {
        this.Toast({
          message: "请勾选协议",
          position: "top",
        });
        return;
      }

      if (this.login) {

        this.loginAPI2(); //登录
        //需求 登录即注册
        // if (this.mobileLogin) {
        //   this.loginAPI3();
        // } else {
        //   this.loginAPI2(); //登录
        // }
      } else {
        if (this.transition) {
          this.loginAPI();
        } else {
          // if (this.captcha === "154890") {
          //   this.registerUser(); //测试验证码154890
          // } else {
          this.$refs.ruleForm.validate("captcha").then(() => {
            // this.verificationCAPTCHA(); //注册
            if (this.bankInfo) {
              this.registerBankUser();
            } else {
              this.registerUser();
            }
          });
          // }
        }
      }
    },
    //获取验证码
    getCode() {
      this.$refs.ruleForm.validate("mobile").then(async () => {
        await this.$API.login
          .sendCAPTCHA({
            mobile: this.mobile,
          })
          .then((res) => {
            this.Toast.success("发送成功");
            this.codeShow = false;
            let t = setInterval(() => {
              this.codeCountDown--;
              if (this.codeCountDown <= 0) {
                this.codeShow = true;
                clearInterval(t);
                this.codeCountDown = 29;
              }
            }, 1000);
          })
          .catch(() => {
            this.Toast.fail("发送失败");
          });
      });
    },
    // //校验验证码
    async verificationCAPTCHA() {
      await this.$API.login
        .verificationCAPTCHA({
          mobile: this.mobile,
          captcha: this.captcha,
        })
        .then((res) => {
          if (res.data.data == null) {
            this.$refs.captcha.validateFailed = true;
            this.$refs.captcha.validateMessage = "验证码错误";
          } else if (res.data.data.result) {
            this.registerUser();
          }
        });
    },
    //注册
    async registerUser() {
      await this.$API.login
        .register({
          object: {
            captcha: this.captcha,
            password: this.password2,
            telNum: this.mobile,
            userType: this.$route.query.PDA ? "PURCHASE" : "CONSUMER",
            referrerTelNum: this.referrerTelNum,
            referrerUserId: sessionStorage.getItem("userUuid")
              ? sessionStorage.getItem("userUuid")
              : "",
            // ENTERPRISE, 企业
            // PLATFORM, 平台
            // CONSUMER, 消费者
            // PURCHASE, 店长
          },
        })
        .then((res) => {
          if (res.data.code === "SUCCESS") {
            this.Toast.success("注册成功");
            //不开放注册店长 区分pda注册和普通注册
            if (this.$route.query.PDA) {
              this.loginAPI();
            } else {
              this.$router.replace("/login");
            }
          } else if (res.data.code === "UNIQUE_VALIDATE") {
            //该账号已注册
            this.Toast.fail("该账号已注册");
          } else {
            //error
            this.Toast.fail(res.data.msg);
          }
        });
    },
    //店长注册后登录
    async loginAPI() {
      const data = await this.$API.login.login({
        password: this.transition ? this.captcha : this.password2,
        username: this.mobile,
      });
      if (data.data.code === "SUCCESS") {
        setToken(data.data.data.token);
        if (this.$store.state.App) {
          // alert("app内打开");
          let info = {
            type: 1,
            data: {
              token: data.data.data.token,
            },
          };
          Android.callAndroid(JSON.stringify(info));
        }
        const user = await this.$API.user.getUser({});
        this.$store.commit("user/setUserInfo", user.data.data.userInfo);
        //店长注册区分pda
        if (this.$route.query.PDA) {
          if (this.transition) {
            //会员转换店长
            this.updateUserType();
          } else {
            //店长注册
            this.$router.replace(
              `/register/registration?PDA=${this.$route.query.PDA}`
            );
          }
        } else {
          this.$router.replace("/register/registration");
        }
      } else {
        this.Toast({
          message: data.data.msg,
          position: "top",
        });
      }
      // USER_NOT_FOUND 用户未找到
      // BAD_CREDENTIALS 密码错误
      // SUCCESS 成功
    },

    async loginAPI2() {
      const data = await this.$API.login.login({
        password: this.mobileLogin ? this.captcha : this.password2,
        username: this.mobile,
      });
      if (data.data.code === "SUCCESS") {
        setToken(data.data.data.token);
        if (this.$store.state.App) {
          // alert("app内打开");
          let info = {
            type: 1,
            data: {
              token: data.data.data.token,
            },
          };
          Android.callAndroid(JSON.stringify(info));
        }
        this.getUserInfo();
      } else if (data.data.code === "BAD_CREDENTIALS_CODE") {
        if (this.mobileLogin) {
          this.Toast({
            message: "验证码错误",
            position: "top",
            type: "fail",
          });
        } else {
          Dialog.confirm({
            title: "密码错误，是否使用手机验证码登录？",
          })
            .then(() => {
              // on confirm
              this.mobileLogin = true;
            })
            .catch(() => {
              // on cancel
            });
        }
      } else {
        this.Toast({
          message: data.data.msg,
          position: "top",
          type: "fail",
        });
      }

      // USER_NOT_FOUND 用户未找到
      // BAD_CREDENTIALS 密码错误
      // SUCCESS 成功
    },
    async loginAPI3() {
      await this.$API.login
        .register({
          object: {
            captcha: this.captcha,
            password: "A8f95E",
            telNum: this.mobile,
            userType: "CONSUMER",
            referrerTelNum: this.referrerTelNum,
            referrerUserId: sessionStorage.getItem("userUuid")
              ? sessionStorage.getItem("userUuid")
              : "",
            // ENTERPRISE, 企业
            // PLATFORM, 平台
            // CONSUMER, 消费者
            // PURCHASE, 店长
          },
        })
        .then(async (res) => {
          if (res.data.code === "SUCCESS") {
            this.Toast.success("注册成功");
            this.loginAPI2();
          } else if (res.data.code === "UNIQUE_VALIDATE") {
            //该账号已注册
            this.loginAPI2();
          } else {
            //error
            this.Toast.fail("验证码错误");
            // this.Toast.fail(res.data.msg);
          }
        });
    },
    async getUserInfo() {
      await this.$store.dispatch("user/getUser");
      await this.$store.dispatch("user/queryPromoterById");

      if (
        sessionStorage.getItem("userUuid") ||
        this.userInfo.userEntity.referrerUserId
      )
        await this.$store.dispatch("user/getTzInfo");
      // window.location.search && this.$store.dispatch("user/getOpenId");
      if (
        this.userType === "PURCHASE" &&
        !this.userInfo.userDetailEntity.getGoodsName
      ) {
        //未完善店长信息
        this.Toast.success("完善店长信息");
        this.$router.push({
          path: "/register/registration",
          query: {
            barcode: this.$route.query.barcode,
            enterpriseId: this.$route.query.enterpriseId,
          },
        });
      } else {
        this.Toast.success("登录成功");
        if (this.$route.query.redirect_uri) {
          this.$router.push(this.$route.query.redirect_uri);
        } else {
          this.$router.replace("/home");
        }
      }
    },
    async updateUserType() {
      await this.$API.user
        .updateUserType({
          object: {
            referrerTelNum: this.referrerTelNum,
            userType: "PURCHASE",
          },
        })
        .then((res) => {
          if (res.data.code === "SUCCESS") {
            this.Toast.success("注册成功");
            this.$router.replace(
              `/register/registration?PDA=${this.$route.query.PDA}`
            );
          } else {
            this.Toast.fail(res.data.msg);
          }
        })
        .catch((err) => {
          console.log(err);
          this.Toast.fail(err);
        });
    },
    async getBankStoreDetail() {
      const data = await this.$API.login.queryBankStoreDetailByUserId({
        object: {
          id: sessionStorage.getItem("userUuid"),
        },
      });
      if (data) this.bankInfo = data.data.data;
      console.log(this.bankInfo);
    },
    async registerBankUser() {
      await this.$API.login
        .insertBankConsumerUser({
          object: {
            bankBranchId: this.bankInfo.bankBranchId, //网点id
            bankStoreId: this.bankInfo.id, //会员店id
            bankStoreName: this.bankInfo.bankStoreName, //会员店名称
            code: this.captcha, //验证码
            name: "", //会员名字
            password: this.password2, //密码
            userId: this.bankInfo.userId, //团长id
            username: this.mobile, //用户
            wechat: "", //微信号
          },
        })
        .then((res) => {
          if (res.data.code === "SUCCESS") {
            if (res.data.data.id === "exist") {
              this.Toast.fail("已注册");
            } else {
              this.Toast.success("注册成功");
              this.$router.replace("/login");
            }
          } else {
            //error
            this.Toast.fail(res.data.msg);
          }
        });
    },
    switchover() {
      this.mobile = "";
      this.captcha = "";
      this.username = "";
      this.password = "";
      this.password2 = "";
      this.checkbox = false;
      this.mobileLogin = false;
      this.$refs.ruleForm.resetValidation();
      if (this.login) {
        if (this.$route.query.bankId) {
          this.$router.replace({
            path: "/register",
            query: {
              bankId: this.$route.query.bankId ? this.$route.query.bankId : "",
            },
          });
        } else {
          this.$router.replace("/register");
        }
      } else {
        if (this.$route.query.bankId) {
          this.$router.replace({
            path: "/login",
            query: {
              bankId: this.$route.query.bankId ? this.$route.query.bankId : "",
            },
          });
        } else {
          this.$router.replace("/login");
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
// letter-spacing 文字间距
.login {
  padding: 71px 48px 25px;
  text-align: center;
  overflow-x: hidden;
  background-color: #ffffff;
  //   font-family: Source Han Sans CN-Regular, Source Han Sans CN ;
  .title {
    margin-bottom: 30px;
    img {
      width: 80px;
      height: 80px;
    }
    h1 {
      font-size: 22px;
    }
    p {
      font-size: 10px;
      color: #999999;
    }
  }
  ::v-deep .van-form {
    .van-cell {
      padding: 12px 0;
      margin-top: 13px;
      align-items: center;
      .van-cell__value {
        position: relative;
        .van-field__right-icon {
          position: absolute;
          top: 50%;
          right: 0;
          margin-top: -12px;
        }
      }
      .van-field__left-icon {
        margin-right: 12px;
        .svg-icon {
          font-size: 18px;
        }
      }
      .van-field__right-icon .svg-icon {
        font-size: 18px;
      }

      .count_down .van-cell__value {
        input {
          font-size: 16px;
        }
        ::-webkit-input-placeholder {
          /* WebKit browsers */
          color: #999;
        }
        :-moz-placeholder {
          /* Mozilla Firefox 4 to 18 */
          color: #999;
        }
      }

      .get_code {
        width: 76px;
        height: 24px;
        background: #ffffff;
        border-radius: 13px 13px 13px 13px;
        opacity: 1;
        border: 1px solid #0787f4;
        font-size: 12px;
        color: #0787f4;
      }
      .count_down {
        height: 24px;
        background: #f6f6f6;
        border-radius: 13px 13px 13px 13px;
        font-size: 12px;
        color: #999;
        padding: 0 8px;
      }
      .protocol {
        font-size: 12px;
        margin-left: 6px;
      }
    }

    .van-cell::after {
      right: 40px;
      left: 40px;
    }
    .protocol_box {
      margin-top: 4px;
      line-height: 18px;
      .van-field__control {
        align-items: flex-start;
      }
      .van-checkbox {
        overflow: inherit;
        margin-top: 2px;
      }
    }
    .protocol_box:after {
      border: none;
    }
    .submit {
      margin-top: 28px;
      // padding: 0 28px;
      .van-button {
        background: #fb5d20;
        border-color: #fb5d20;
        font-size: 16px;
      }
    }
    // .submit {
    //   position: absolute;
    //   bottom: 0;
    //   left: 0;
    //   right: 0;
    //   padding-bottom: 50px;
    //   background: #fff;
    //   .van-button {
    //   width: 78%;
    //   height: 42px;
    //   font-size: 16px;
    //   background: linear-gradient(90deg, #31b0fc 0%, #0787f4 100%);
    //   box-shadow: 0px 2px 6px 1px rgba(22, 119, 201, 0.3);
    //   border-radius: 21px 21px 21px 21px;
    //   margin: 0 auto;
    // }
  }
  .switchover {
    margin-top: 20px;
    font-size: 14px;
    color: #666666;
    display: flex;
    justify-content: space-between;
    span {
      color: #0787f4;
    }
    a {
      color: #666;
    }
  }
  .mobile_login {
    margin-top: 50px;
    font-size: 16px;
    font-weight: bold;
    color: #333333;
    .svg-icon {
      font-size: 14px;
      margin-left: 4px;
      margin-bottom: 1px;
    }
  }
}
</style>
